import type { LinksFunction } from '@remix-run/node'
import { Outlet, useOutletContext } from '@remix-run/react'
import { ContentHeader } from '~/components'

import style from './style.css?url'
import callout from '~/styles/components/callout.css?url'

// Components (probably need to consolidate these)
import { Flash, styles } from '~/components-v2'
import { Footer } from '~/components-v2/footer/footer'

/**
 * Leveraged by `_content.*` routes
 */
export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: style },
  { rel: 'stylesheet', href: callout },
  { rel: 'stylesheet', href: styles.accordion },
  { rel: 'stylesheet', href: styles.stack },
]

export default function () {
  return (
    <>
      <Flash />
      <ContentHeader />
      <main>
        <Outlet context={useOutletContext()} />
      </main>
      <Footer />
    </>
  )
}
